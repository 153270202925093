import React from "react";

import "./styles/MapScene.css";
import TheMap from "../images/Map.png";

const MapScene = (props) => {
  const mapTypeClickHandler = (e) => {
    //console.log(e)

    console.log(e.target.height + " " + e.target.width);
    console.log(e);
    let topmargin = 45;
    let OriginalWidth = 1637;
    let OriginalHeight = 1081;

    let xPercent = e.target.width / OriginalWidth;
    let yPercent = e.target.height / OriginalHeight;

    let x = e.clientX;
    let y = e.clientY;

    console.log(x + " " + y);
    let box1 = { x1: 230, y1: 521, x2: 360, y2: 630 };
    let box1adjusted = {
      x1: box1.x1 * xPercent,
      y1: box1.y1 * yPercent + topmargin,
      x2: box1.x2 * xPercent,
      y2: box1.y2 * yPercent + topmargin,
    };

    let box2 = { x1: 240, y1: 240, x2: 400, y2: 384 };
    let box2adjusted = {
      x1: box2.x1 * xPercent,
      y1: box2.y1 * yPercent + topmargin,
      x2: box2.x2 * xPercent,
      y2: box2.y2 * yPercent + topmargin,
    };

    let box3 = { x1: 690, y1: 231, x2: 840, y2: 360 };
    let box3adjusted = {
      x1: box3.x1 * xPercent,
      y1: box3.y1 * yPercent + topmargin,
      x2: box3.x2 * xPercent,
      y2: box3.y2 * yPercent + topmargin,
    };

    let box4 = { x1: 826, y1: 496, x2: 1000, y2: 620 };
    let box4adjusted = {
      x1: box4.x1 * xPercent,
      y1: box4.y1 * yPercent + topmargin,
      x2: box4.x2 * xPercent,
      y2: box4.y2 * yPercent + topmargin,
    };

    let box5 = { x1: 630, y1: 744, x2: 800, y2: 885 };
    let box5adjusted = {
      x1: box5.x1 * xPercent,
      y1: box5.y1 * yPercent + topmargin,
      x2: box5.x2 * xPercent,
      y2: box5.y2 * yPercent + topmargin,
    };
    let id = 99;
    console.log(box1);
    console.log(box1adjusted);
    console.log("Hit point " + e.clientX + " " + e.clientY);

    let boxes = [
      box1adjusted,
      box2adjusted,
      box3adjusted,
      box4adjusted,
      box5adjusted,
    ];

    for (let i = 0; i < boxes.length; i++) {
      if (x > boxes[i].x1 && x < boxes[i].x2) {
        console.log("Hit box " + i + " - Inside X");
        if (y > boxes[i].y1 && y < boxes[i].y2) {
          console.log("Hit box " + i + " - Inside Y");
          id = i;
        }
      }
    }

    if (id === 99) {
      console.log("Hit nothing");
      return;
    }
    if (props.game.firstRun) {
      props.game.firstRun = false;
      props.toCity(id);
      return;
    } else {
      props.toDesert(id);
    }
  };

  return (
    <div className="map-scene">
      <img
        onClick={mapTypeClickHandler}
        src={TheMap}
        alt="Map of the desert with towns to goto"
      ></img>
    </div>
  );
};

export default MapScene;
