import React, { useState } from "react";

import "./styles/DrinkScene.css";
const DrinkScene = (props) => {
  const menuStates = {
    MENU: 0,
    DRANKTOOMUCH: 1,
    DRINKING: 2,
  };
  const [menu, setMenu] = useState(menuStates.MENU);
  const [strength, setStrength] = useState(props.howMuchStrength);
  const [drink, setDrink] = useState(0);
  //let drink = 0;
  let drinkLimit = 40;

 

  function t2 () {
    
    setTimeout(() => {
      console.log("TIMEOUT You are done drinking");
      setMenu(menuStates.MENU);
    }, 3000)
  }

  const drinkRedEye = () => {
    if (props.howMuchMoney() < 2) {
      console.log("You dont have enough money");
      return;
    }

    console.log("You are drinking RedEye 25 Strength Points");
    //increase drink by 25
    //drink = drink + 25;
    setDrink(drink+25);
    //add to strength

    props.addMoney(-2);
    props.addValueToStrength(25);

    
    
    console.log("drink is " + drink + " drinkLimit is " + drinkLimit) 
    if (drink > drinkLimit) {
      drinkLimitReached(); 
    }
    else {
      setMenu(menuStates.DRINKING);
      t2()
    }
  };
  const drinkBeer = () => {
    if (props.howMuchMoney() < 1) {
      console.log("You dont have enough money");
      return;
    }
    console.log("You are drinking Beer 10 Strength Points");
    setDrink(drink+10);
    props.addMoney(-1);
    props.addValueToStrength(10);
    //add to strength
    if (drink > drinkLimit) {
      drinkLimitReached();
    } else {
      setMenu(menuStates.DRINKING);
      t2()
    }
  };
  const drinkLimitReached = () => {
    console.log("You have drank too much");
    //get random number between 10 and 60
    let random = getRandomNumber(10, 60) * -1;
    props.addValueToStrength(random);
    setStrength(props.howMuchStrength());
    setMenu(menuStates.DRANKTOOMUCH);

    // subtract from strength
  };
  const toDone = () => {
    console.log("You are done drinking");
    props.toMenu();
    //props.changeScene(gameStates.INCITY_SCENE);
  };

  //a random number between 10 and 60
  const getRandomNumber = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  return (
    <div className="drinkscene">
      {menu === menuStates.MENU && (
        <div>
          <p>What is your poision ? Says the bar tender</p>
          <div className="row">
            <div className="column">
              <h4 onClick={drinkRedEye}>RedEye</h4>
              <h4 onClick={drinkBeer}>Beer</h4>
            </div>
            <div className="column">
              <h4 onClick={drinkRedEye}>$2</h4>
              <h4 onClick={drinkBeer}>$1</h4>
            </div>
          </div>
          <div className="done-drinking" onClick={toDone}>
            Done Drinking
          </div>
        </div>
      )}

      {menu === menuStates.DRANKTOOMUCH && (
        <div className="dranktomuch">
          <p onClick={toDone}>You have drank to much </p>
          <p onClick={toDone}>You feel dizzy, stumble and puke over</p>
          <p onClick={toDone}>lonesome cowboy, who hits you very</p>
          <p onClick={toDone}>hard, your strength is now {strength}</p>
          <p onClick={toDone}></p>
          <p onClick={toDone}>you get thrown out of the saloon</p>
          <p onClick={toDone}></p>
          <p onClick={toDone}>click to continue</p>
        </div>
      )}

      {menu === menuStates.DRINKING && 
        <div className="drinking">

          Glug Glug Glug
        
        </div>}
    </div>
  );
};

export default DrinkScene;
