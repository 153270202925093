import React from "react";


import  "./styles/SheriffOfficeScene.css";

const SheriffOfficeScene = (props) => {
    return (
        <div>
            <h1>Sheriff Office Scene</h1>
            <p>Here is the sheriff office scene</p>
            </div>

    )
}

export default SheriffOfficeScene;