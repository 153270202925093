import React from "react";
import "./styles/ShootOutScene.css";

const ShootOutScene = (props) => {
    return (
        <div>
        <h1>Shoot Out Scene</h1>
        <p>Here is the shoot out scene</p>
        </div>
    );
    }

export default ShootOutScene;
