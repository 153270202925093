import React from "react";
import "./styles/TelegraphOfficeScene.css";
//import { gameStates } from "../GameData";

const TelegraphOfficeScene = (props) => {
    return (
        <div>
        <h1>Telegraph Office Scene</h1>
        <p>Here is the telegraph office scene</p>
        </div>
    );
    };
export default TelegraphOfficeScene;
