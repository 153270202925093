import React,{useState} from "react";
import TV from "../images/TV.png";
import DesertChoices from "./DesertChoices";

import "./styles/DesertScene.css";
const DesertScene = (props) => {
    const [day, setDay] = useState(1);
    //const [eventText, setEventText] = useState("");
    const randomEvent = () =>{
        
        setEventID(re());
    }

    function re(){
        let random = Math.floor(Math.random()*4);
        console.log("random:"+random)
        return random;
    }

    const [eventID, setEventID] = useState(re());
    let eventText = ["Your are being chased by bandits!, what do you do?",
        "Your are being chased by a rattle snake, what do you do?",
        "Your are being chased by a massive bull, what do you do?",
        "Nothing Eventful Happened!",
        "You have Defeated the Bandits!",
        "You have Defeated the Rattle Snake!",
        "You have Defeated the Bull!",
        "You have outrun the Bandits!",
        "You have outrun the Rattle Snake!",
        "You have outrun the Bull!"];
    let eventTextFight = [
        "You have Defeated the Bandits!",
        "You have Defeated the Rattle Snake!",
        "You have Defeated the Bull!"]
    let eventTextRun = [
        "You have outrun the Bandits!",
        "You have outrun the Rattle Snake!",
        "You have outrun the Bull!"];
    let eventTextBribe = ["You have bribed the Bandits!"]

    
    

    const nextDay = () => {
        randomEvent();
        setDay((prevDay) => {
            return prevDay + 1;
        });
    }
    
  return (
    <div>
      <div className="desertscene">
        <img src={TV} alt="TV" className="tv" />
      </div>

      <div className="desertscenetext" >
        <h1>Your are on day {day} of travel</h1>
        <p>You are making your way across the desert</p>
        <p>{eventText[eventID]}</p>
        {eventID !== 3 && <DesertChoices 
        nextDay={nextDay} 
        eventTextRun={eventTextRun} 
        eventTextBribe={eventTextBribe} 
        eventTextFight={eventTextFight}/>}
        {eventID === 3 && <button onClick={nextDay}>Continue Journey</button>}
        
      </div>
      <div>
        
        </div>

    </div>
  );
};

export default DesertScene;
