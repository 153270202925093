import React from "react";
import "./styles/SplashScreen.css";
import { gameStates } from "../GameData";

import SplashScreenImage from "../images/Splash.png";

const SplashScreen = (props) => {
  const splashScreenClickHandler = () => {
    props.changeGameState(gameStates.OPENING_SCENE);
  };

  return (
    <div className="splashscreenmask" onClick={splashScreenClickHandler}>
      <img
        src={SplashScreenImage}
        alt="splashscreen"
        className="splashscreen"
      />
    </div>
  );
};

export default SplashScreen;
