export const staticOpeningText = [
  "THE STORY SO FAR....\n",
  "I stood over a man in a blue checked shirt,\n",
  "He mutters the words;\n",
  "THE MAN WITH PATCH ON LEFT EYE...\n",
  "He was dead, a hole\n",
  "in his chest that had been caused by a single gunshot.\n\n",
  "My boots creaked as I stepped back and looked at the man,\n",
  "the smell of gunpowder heavy in the air. I knew I hadn't,\n",
  "killed him, but I had been the first on the scene\n",
  "and the sheriff was looking at me with suspicion in his eyes.\n",
  "The Bullseye Bandits killed this man\n",
  ", a lawless gang of men who had been terrorizing the town for weeks.\n",
  "They had struck again, and now I was the prime suspect\n",
  "I turned to the sheriff and met his gaze,\n",
  "standing my ground as he continued to look me up and down.\n\n",
  "HE SHOUTS; 'DON'T MOVE OR I'LL FILL YOU FULL OF LEAD!'\n",
  "YOU RUN OFF INTO THE NIGHT WITH BULLETS FLYING PAST YOUR HEAD\n",
  "\n",
];

export const staticOpeningTextold = [
  "THE STORY SO FAR....\n",
  "\n",
  "YOU ARE WALKING HOME ONE NIGHT WHEN YOU HEAR A GUNSHOT.\n",
  "YOU FIND A DYING MAN LYING IN THE ROAD. HE MUTTERS THE WORDS;\n",
  " 'THE WILD BUNCH...'\n",
  "THE MAN WITH PATCH ON LEFT EYE...\n",
  "THE MAN COUGHS AND DIES.\n",
  "YOU PICKUP A COLT 45 THAT MUST BELONG TO THE MURDERER.\n",
  "AT THAT MOMENT THE SHERIFF RIDES UP.\n",
  "HE SEES YOU WITH THE BODY AND THE GUN IN YOUR HAND\n",
  "HE SHOUTS; 'DON''T MOVE OR I'LL FILL YOU FULL OF LEAD!'\n",
  "YOU RUN OFF INTO THE NIGHT WITH BULLETS FLYING PAST YOUR HEAD\n",
  "\n",
  "PRESS ANY KEY",
];

export const wantedManText =
  "YOU ARE A WANTED MAN\n" +
  "YOUR ONLY CHANCE TO PROVE YOUR INNOCENT IS TO FIND THE REAL\n" + 
  "KILLER A MEMBER OF THE DREADED\n" +
  "              'BULLSEYE BANDITS!\n";

export const initText = [
  "YOU HAVE $",
  "50",
  "YOU MAY CATCH THE STAGE TO ANY OF THE ABOVE TOWNS TO START YOUR SEARCH.\n",
  "CLICK ON A TOWN",
];

export const cityText = [
  "YOU ARE IN",
  "THIS TOWN",
  "YOU CAN DO THE FOLLOWING",
  "GO TO SALOON",
  "GO TO THE SHERIFFS OFFICE",
  "GO TO THE STORE",
  "GO TO THE TELEGRAPH OFFICE",
  "MOVE ON",
  "STATUS",
];

export const telegraphOfficeText = [
  "YOU GO TO THE TELEGRAPH OFFICE",
  "THE CLERK SAYS HE WILL SHOW YOU THE LATEST TELEGRAPH FOR $",
  "MONEY AMOUNT",
  "YES/NO",
];

export const telegraphText1 = [
  "TELEGRAPH FROM",
  "INSERT CITY",
  "CITY",
  "PINKERTON AGENT CHARLES A. SIRINGO ARRIVE TODAY IN SEARCH OF FUGATIVE KNOWN AS",
  "YOUR NAME",
];

export const telegraphText2 = [
  "TELEGRAPH FROM",
  "GREAT NORTHEN RAILROAD CO.",
  "CITY",
  "WILD BUNCH CARRIED OUT RAID ON TRAIN REWARDS RAISED BY $50",
];

//export default openingText;
export const gameStates = {
  START: 0,
  OPENING_SCENE: 1,
  MAP_SCENE: 2,
  SALOON_SCENE: 3,
  TELEGRAPH_OFFICE_SCENE: 4,
  SHERIFFS_OFFICE_SCENE: 5,
  STORE_SCENE: 6,
  DESERT_SCENE: 7,
  SHOOT_OUT_SCENE: 8,
  INCITY_SCENE: 9,
};

// get random index from array killerFeatures
export const killersFeatures = [
  "A MAN WITH A PATCH ON HIS RIGHT EYE\n",
  "A MAN WITH A PATCH ON HIS LEFT EYE\n",
  "A WOMAN WITH A SCAR ON HER RIGHT CHEEK\n",
  "A WOMAN WITH A SCAR ON HER LEFT CHEEK\n",
  "A MAN WITH A SCAR ACROSS HIS RIGHT EYE\n",
  "A MAN WITH A SCAR ACROSS HIS LEFT EYE\n",
  "A WOMAN WITH A SCAR ON HER FOREHEAD\n",
];

export const cityNames = [
  "Dead Man's Gulch",
  "Rattlesnake Gulch",
  "Gold Town",
  "Gallows Hill",
  "Outlaw Canyon",
];


                              