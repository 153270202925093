import {
  gameStates,
  staticOpeningText,
  killersFeatures,
  wantedManText,
  cityNames,
} from "./GameData";

export class GameEngine {
  // game enum states

  // get random index from array killerFeatures
  getRandomKiller() {
    //console.log(killersFeatures.length);
    return Math.floor(Math.random() * killersFeatures.length);
  }

  constructor() {
    this.gameRunning = false;
    this.playerMoney = 50;
    this.strength= 100
    this.gameState = gameStates.START;
    this.currentCity = cityNames[0];
    this.firstRun = true;
    this.killerId = this.getRandomKiller();
    this.openingText = this.openingText();
    this.textList = [this.openingText, wantedManText, "test3"];
 
  }
  
  
  openingText() {
    let ot = [...staticOpeningText];
    ot[3] = killersFeatures[this.killerId];
    console.log(ot);
    return ot.join("").toString();
  }

  movetoCity(cityId) {
    this.currentCity = cityNames[cityId];
    console.log("movedToCity: " + this.currentCity);
  }
 
  
}
