import React, { useState } from "react";
import "./App.css";
import { GameEngine } from "./GameEngine";
import TopBar from "./components/TopBar";
import OpeningScene from "./components/OpeningScene";
import { gameStates } from "./GameData";
import SplashScreen from "./components/SpashScreen";
import MapScene from "./components/MapScene";
import StoreScene from "./components/StoreScene";
import SheriffOfficeScene from "./components/SheriffOfficeScene";
import TelegraphOfficeScene from "./components/TelegraphOfficeScene";
import DesertScene from "./components/DesertScene";
import SaloonScene from "./components/SaloonScene";
import ShootOutScene from "./components/ShootOutScene";
import InCityScene from "./components/InCityScene";
import { cityNames } from "./GameData";

import BottomBar from "./components/BottomBar";

function App() {
  let game = new GameEngine();
  const [currentGameObject, setCurrentGameObject] = useState(game);
  const [sceneSelected, setSceneSelected] = useState(gameStates.START);
  const [currentCity, setCurrentCity] = useState(game.currentCity);

  const gameStateValues = Object.values(gameStates);
  let currentGameStateIndex = 0;
  const testButtonHandler = () => {
    //console.log("You pressed testButton  ")
    testStates();
  };

  const toOpeningScene = () => {
    changeScene(gameStates.OPENING_SCENE);
  };
  const toMapScene = () => {
    changeScene(gameStates.MAP_SCENE);
  };

  const toCityScene = (id) => {
    console.log("toCityScene" + id);
    changeScene(gameStates.INCITY_SCENE);
    changeCity(id);
  };
  const changeCity = (id) => {
    setCurrentCity((prevCity) => {
      return cityNames[id];
    });
  };
  const changeScene = (newScene) => {
    setSceneSelected((prevScene) => {
      return newScene;
    });
  };
  const getNextGameState = () => {
    currentGameStateIndex = gameStateValues.indexOf(
      currentGameObject.gameState
    );
    console.log("currentGameStateIndex: " + currentGameStateIndex);
    if (currentGameStateIndex < gameStateValues.length - 1) {
      currentGameStateIndex++;
    } else {
      currentGameStateIndex = 0;
    }
    console.log("currentGameStateIndex: " + currentGameStateIndex);
    return gameStateValues[currentGameStateIndex];
  };

  const testStates = () => {
    //get next gamestates
    let nextGameState = getNextGameState();
    setCurrentGameObject((prevGameObject) => {
      let temp = prevGameObject;
      temp.gameState = nextGameState;
      temp.firstRun = false;
      return temp;
    });
    changeScene(nextGameState);
    console.log("NextGameState = " + nextGameState);
    console.log(sceneSelected);
  };
  const toDesertScene = (cityId) => {
    changeScene(gameStates.DESERT_SCENE);
  };

  const toStoreScene = () => {
    changeScene(gameStates.STORE_SCENE);
  };
  const toSaloonScene = () => {
    changeScene(gameStates.SALOON_SCENE);
  };
  const toTelegraphScene = () => {
    changeScene(gameStates.TELEGRAPH_OFFICE_SCENE);
  };
  const toSheriffScene = () => {
    changeScene(gameStates.SHERIFFS_OFFICE_SCENE);
  };

  const addMoney = (value) => {
    setCurrentGameObject((prevGameObject) => {
      let temp = prevGameObject;
      temp.playerMoney += value;
      return temp;
    });
  };
  function howMuchMoney() {
    return currentGameObject.playerMoney;
  }
  function howMuchStrength() {
    return currentGameObject.strength;
  }
  const addStrength = () => {
    setCurrentGameObject((prevGameObject) => {
      let temp = prevGameObject;
      temp.strength += 10;
      return temp;
    });
  };

  const addValueToStrength = (value) => {
    setCurrentGameObject((prevGameObject) => {
      let temp = prevGameObject;
      temp.strength += value;
      return temp;
    });
  };

  const showGameObject = () => {
    console.log(currentGameObject);
  };

  //console.log("currentGameState: " + currentGameState);
  return (
    <div>
      <TopBar
        testButtonHandler={testButtonHandler}
        moneyButtonHandler={addMoney}
        strengthButtonHandler={addStrength}
        showGameObject={showGameObject}
      />

      
      <div className="row">
        <div className="leftcolumn">
          <div className="card">
            <div>
              {sceneSelected === gameStates.START && (
                <SplashScreen changeGameState={toOpeningScene} />
              )}
              {sceneSelected === gameStates.OPENING_SCENE && (
                <OpeningScene
                  changeGameState={toMapScene}
                  game={currentGameObject}
                />
              )}
              {sceneSelected === gameStates.MAP_SCENE && (
                <MapScene
                  toDesert={toDesertScene}
                  toCity={toCityScene}
                  game={currentGameObject}
                />
              )}
              {sceneSelected === gameStates.SALOON_SCENE && (
                <SaloonScene
                  changeScene={changeScene}
                  addValueToStrength={addValueToStrength}
                  addMoney={addMoney}
                  howMuchMoney={howMuchMoney}
                  howMuchStrength={howMuchStrength}
                />
              )}
              {sceneSelected === gameStates.DESERT_SCENE && <DesertScene />}
              {sceneSelected === gameStates.TELEGRAPH_OFFICE_SCENE && (
                <TelegraphOfficeScene />
              )}
              {sceneSelected === gameStates.SHERIFFS_OFFICE_SCENE && (
                <SheriffOfficeScene />
              )}
              {sceneSelected === gameStates.STORE_SCENE && (
                <StoreScene city="hello" />
              )}
              {sceneSelected === gameStates.SHOOT_OUT_SCENE && (
                <ShootOutScene />
              )}
              {sceneSelected === gameStates.INCITY_SCENE && (
                <InCityScene
                  city={currentCity}
                  money={currentGameObject.playerMoney}
                  strength={currentGameObject.strength}
                  toMap={toMapScene}
                  toStore={toStoreScene}
                  toSaloon={toSaloonScene}
                  toTelegraph={toTelegraphScene}
                  toSherriff={toSheriffScene}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <BottomBar />
    </div>
  );
}

export default App;
