import React,{useState} from "react";
import { gameStates } from "../GameData";
import DrinkScene from "./DrinkScene";
import TV from "../images/TV.png";

import "./styles/SaloonScene.css";
const SaloonScene = (props) => {

  const menuStates = {
    MENU: 0,
    DRINK: 1,
    POKER: 2,
    LOOK: 3,
  };


    
    const [menu, setMenu] = useState(menuStates.MENU);

  const toDrink = () => {
    console.log("You are drinking");
    setMenu(menuStates.DRINK);
  };
  const toPoker = () => {
    console.log("You are playing poker");
  };
  const toLook = () => {
    console.log("You are looking around");
    // a xx walks in to the saloon witeh
    // ..no eyebros
    // do you want to challenge

    // or nobody special here
  };
  const toLeave = () => {
    console.log("You are leaving");
    props.changeScene(gameStates.INCITY_SCENE);
  };
  const toMenu = () => {
    console.log("You are leaving");
    setMenu(menuStates.MENU);
  };

  return (
    <div className="saloonscene">
      <img src={TV} alt="TV" className="tv" />
      <div className="saloonscenetext">
        <h1>You are in the Saloon</h1>
        {menu === menuStates.MENU && (
            <div>
            
            <h2>Choose what you want to do</h2>
            <p></p>
            <ul>
              <li onClick={toDrink} toMenu={toMenu}>
                Drink
              </li>
              <li onClick={toPoker}>Play Poker</li>
              <li onClick={toLook}>Look Around</li>
              <li onClick={toLeave}>Leave</li>
            </ul>
            </div> )
        }

        {menu === menuStates.DRINK && (
            <DrinkScene addValueToStrength={props.addValueToStrength}
            addMoney={props.addMoney}
            howMuchMoney={props.howMuchMoney}
            howMuchStrength={props.howMuchStrength}
            toMenu={toMenu}/> )
        }
        </div>
    </div>
  );
};

export default SaloonScene;
