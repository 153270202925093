import React from "react";
import "./styles/BottomBar.css";
const BottomBar = (props) => {
    console.log(props)
    
  return (
    <div className="bottom-bar">
      <h1>THIS IS GAME IS UNDER DEVELOPMENT</h1>
    </div>
  );
}

export default BottomBar;
