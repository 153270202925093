import React from "react";
import "./styles/DesertChoices.css";

const DesertChoices = (props) => {

    const Fight = () => {
        console.log("Fight");
        const fight = document.getElementById("fight").value;
        console.log(fight);
    }
    const Run = () => {
        console.log("Run");
    }
    const Bribe = () => {
        console.log("Bribe");    
        const bribe = document.getElementById("bribe").value;
        console.log(bribe);
    }
   


  return (
    <div>
    <div className="desertchoices">
        <button onClick={Fight}>Fight
            </button><input type="number" id="fight" name="fight" min="1" max="300" defaultValue="1">
        </input>
        
        <button onClick={Bribe}>Bribe
            
            </button><input type="number" id="bribe" name="bribe" min="1" max="300" defaultValue="10"></input>
        
    </div>
    <div className="runbutton">
    <button  onClick={Run}>Run</button>
        </div>
        
    </div>
    )
  };

  export default DesertChoices;
