import React from "react";
import "./styles/TopBar.css";

const TopBar = (props) => {
  return (
    <div className="top-bar">
      
      <button onClick={props.showGameObject}> ShowGameObject </button>
    </div>
  );
};

export default TopBar;
