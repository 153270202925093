import React from "react";

import "./styles/StoreScene.css";
//import { gameStates } from "../GameData";

const StoreScene = (props) => {

    return(
    <div className="Store">
        <div>Store</div>
        <button>Horse $10</button>
        <button>Saddle $10</button>
        <button>Gun $10</button>
        <button>Food $10</button>
        <button>Water $10</button>
        <button>Medicine $10</button>
        <button>Exit Store $10</button>
    </div>
    )
}

export default StoreScene;