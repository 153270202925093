import React, { useState } from "react";

import "./styles/OpeningScene.css";
import TeleTyper from "./TeleTyper";
import { gameStates } from "../GameData";
import TV from "../images/TV.png";

const OpeningScene = (props) => {
  const [sceneIndex, setSceneIndex] = useState("0");

  const teleTypeDelay = 30;
  const teleTypeClickHandler = () => {
    console.log("You clicked the text - next part");
    switch (sceneIndex) {
      case "0":
        setSceneIndex("1");
        break;
      case "1":
        console.log("start the game moving");
        props.changeGameState(gameStates.MAP_SCENE);
        break;
      default:
        console.log("default");
    }
  };
  console.log("sceneIndex: " + sceneIndex);
  return (
    <div className="openingscene">
      <img src={TV} alt="TV" className="tv" />
      <div className="tvtext">
      {sceneIndex === '0' && (
        <TeleTyper
          text={props.game.textList[0]}
          characterDelay={teleTypeDelay}
          teleTypeClickHandler={teleTypeClickHandler}
        />
      )}
      {sceneIndex === '1' && (
        <TeleTyper
          text={props.game.textList[1]}
          characterDelay={teleTypeDelay}
          teleTypeClickHandler={teleTypeClickHandler}
        />
      )}
      </div>
    </div>
  );
};

export default OpeningScene;
