import React, { useState } from "react";
import "./styles/InCityScene.css";
import TV from "../images/TV.png";

const InCityScene = (props) => {
  const [showingStatus, setShowingStatus] = useState(false);
  const toSaloon = () => {
    props.toSaloon();
  };
  const toSherriff = () => {
    props.toSherriff();
  };
  const toStore = () => {
    props.toStore();
  };
  const toTelegraph = () => {
    props.toTelegraph();
  };
  const toMap = () => {
    props.toMap();
  };
  const checkStatus = () => {
    //print out status on screen
    setShowingStatus((previousStatus) => {
      return !previousStatus;
    });
  };

  return (
    <div className="incityscene">
      <img src={TV} alt="TV" className="tv" />

      {showingStatus === false && (
        <div className="incityscenetext">
          <h1>{props.city}</h1>
          <p>
            You have ${props.money} your strength is {props.strength}
          </p>
          <p>You can do the following things in this city:</p>
          <ul>
            <li onClick={toSaloon}>Go to Saloon</li>
            <li onClick={toSherriff}>Go to Sherriffs' Office</li>
            <li onClick={toStore}>Go to Store</li>
            <li onClick={toTelegraph}>Go to Telegraph Office</li>
            <li onClick={toMap}>Move On</li>
            <li onClick={checkStatus}>Check Status</li>
          </ul>
        </div>
      )}
      {showingStatus === true && (
        <div onClick={checkStatus}>
          <div className="incityscenetext">
            <h1>{props.city}</h1>
            <p>
              You have ${props.money} your strength is {props.strength}
            </p>
            <p>This is your status</p>
            <ul>
              <li> list all items </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default InCityScene;
